import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forbiddon',
  templateUrl: './forbiddon.component.html',
  styleUrls: ['./forbiddon.component.css']
})
export class ForbiddonComponent implements OnInit {

  constructor() { }
  currentYear: number = new Date().getFullYear();
  ngOnInit() {
  }

  
}
