import { Injectable } from '@angular/core';
import { LocalStorageKeys } from '../common/constant';
import { MqttSubscribeService } from '../core/services/mqtt-subscribe.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  errorData: {};
  get LocalStorageKeys() { return LocalStorageKeys; }
  constructor( private mqttService: MqttSubscribeService,
  ) { }

  redirectUrl: string;

  isLoggedIn() {
    if (localStorage.getItem(LocalStorageKeys.user)) {
      const currentUser = JSON.parse(localStorage.getItem(LocalStorageKeys.user));
      const expiredate = new Date(currentUser.expiresIn);
      const currentDate = new Date();
      if (expiredate < currentDate) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  getAuthorizationToken() {
    const currentUser = JSON.parse(localStorage.getItem(LocalStorageKeys.user));
    return currentUser.token;
  }
  getAuthorizationFotaToken() {
    const currentUser = JSON.parse(localStorage.getItem(LocalStorageKeys.fota_access_token));
    return currentUser.access_token;
  }

  logout() {
    localStorage.clear();
    if (this.mqttService.isMqttConnected) {
      this.mqttService.disConnect();
    }
  }

}

