import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { ForbiddonComponent } from './page-error/forbiddon/forbiddon.component';
import { ServerDownComponent } from './page-error/server-down/server-down.component';


const routes: Routes = [
    
    {
        path: '',
        loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'auth',
        loadChildren: () => import('./authentication/authentication.module').then((a) => a.AuthenticationModule)
    },
    {
        path: 'forbidden',
        component: ForbiddonComponent
    },
    {
        path: 'server-down',
        component: ServerDownComponent
    },
    {
        path: 'error',
        loadChildren: () => import('./page-error/server-error/server-error.module').then((m) => m.ServerErrorModule)
    },
    {
        path: 'access-denied',
        loadChildren: () => import('./page-error/access-denied/access-denied.module').then((m) => m.AccessDeniedModule)
    },
    { path: 'not-found', loadChildren: () => import('./page-error/not-found/not-found.module').then((m) => m.NotFoundModule) },
    { path: '**', redirectTo: 'not-found' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
