import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MQTT_SERVICE_OPTIONS } from './common/common';
import { MqttModule, MqttService } from 'ngx-mqtt';
import { AppHttpInterceptor } from './auth/http.interceptor';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ExcelModule } from '@progress/kendo-angular-grid';
import { RouterModule } from '@angular/router';


export function mqttServiceFactory() {
    return new MqttService(MQTT_SERVICE_OPTIONS);
}

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        NoopAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule.forRoot(),
        NgxSpinnerModule,
        MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
        LayoutModule,
        ExcelModule
    ],
    declarations: [AppComponent],
    providers: [
        AuthGuard,
         { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
