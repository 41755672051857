import { HttpHeaders } from '@angular/common/http';
import { LocalStorageKeys } from './constant';
import { IMqttServiceOptions } from 'ngx-mqtt';
import { ApplicationListResponseModel } from '../core/models/response/ApplicationListResponseModel';
import { NameValue } from '../core/models/response/namevalue';
import { PageSizeItem } from '@progress/kendo-angular-grid';


if (JSON.parse(localStorage.getItem('access_token'))) {
  var client = 'access:' + JSON.parse(localStorage.getItem('access_token')).access_token;
}
declare var apiConfig: any;

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  connectOnCreate: false,
  hostname: apiConfig.mqttHostname,
  port: apiConfig.mqttPort,
  protocol: 'ws',
  path: '/mqtt',
  clientId: client
};
export class Common {
  public static applications: ApplicationListResponseModel[];
  static isFotaSubscribe: boolean;
  static companyNameValidationPattern = '^[ a-zA-Z0-9]{3,30}$';
  static token: string;
  static FotaToken: string;
  static userName: string;
  static userRole: string;
  static userId: number;
  static organizationName: string;
  static shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  get LocalStorageKeys() { return LocalStorageKeys; }

  static passwordValidationPattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#$^+=!*()@%&]).{8,}$';
  // tslint:disable-next-line: max-line-length
  static emailValidationPattern = '^(([^<>()[\\]\\.,;:\\s@\\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\\"]+)*)|(\\\".+\\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

  static deviceIdPattern = "^[A-Fa-f0-9]+$";
  static numberPattern = '^[0-9]*[1-9][0-9]*$';//0 not allowed
  static numberConfigPatterns = '^(0|[1-9][0-9]*)$'; //0 allowed
  static pingIntervalPatterns = '^(3[0-9]|[4-9][0-9]|1[01][0-9]|120)$';
  static networkDiagIntervalPattern = '^(180[0-9]|18[1-9][0-9]|19[0-9]{2}|2[0-9]{3}|3[0-5][0-9]{2}|3600)$'
  static powerAndHeartBeatIntervalPattern = '^(12[0-9]|1[3-9][0-9]|[2-5][0-9]{2}|600)$'
  static portConfigPatterns = '^([1-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$'
  static ipv4Pattern = "^([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\." + "([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\." +
    "([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\." +
    "([01]?\\d\\d?|2[0-4]\\d|25[0-5])$"
  static ipv6Pattern = '^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$';
  static numberPatternDecimal = '^([-+,0-9.]+)$';//decimal allowed
  static unicastIntervalPettern = '^(1[5-9]|[2-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$';
  static brodcastDwellInterval = '^(10[0-9]|1[1-9][0-9]|2[0-4][0-9]|25[0-5])$';
  static broadcastIntervalPattern = '^(10[0-9]|1[1-9][0-9]|[2-9][0-9]{2}|[1-9][0-9]{3,6}|1[0-5][0-9]{6}|16[0-6][0-9]{5}|167[0-6][0-9]{4}|1677[0-6][0-9]{3}|16777[01][0-9]{2}|1677720[0-9]|1677721[0-5])$'
  static lfnBroadcastIntervalPattern = '^(1[0-9]|[2-9][0-9]|[1-5][0-9]{2}|600)$'
  static lfnBroadcastSyncPeriod = '^([1-9]|[1-5][0-9]|60)$'
  static pmkLifeTime = '^([1-9]|[1-9][0-9]{1,4}|1[0-6][0-9]{4}|17[0-4][0-9]{3}|175[01][0-9]{2}|175200)$'
  static ptkLifeTime = '^([1-9]|[1-9][0-9]{1,4}|1[0-6][0-9]{4}|17[0-4][0-9]{3}|175[01][0-9]{2}|175200)$'
  static gtkExpireOffset = '^([0-9]|[1-9][0-9]{1,3}|[1-3][0-9]{4}|4[0-2][0-9]{3}|43[01][0-9]{2}|43200)$'
  static lgtkExpireOffset = '^([0-9]|[1-9][0-9]{1,4}|1[01][0-9]{4}|12[0-8][0-9]{3}|129[0-5][0-9]{2}|129600)$'
  static ntwk_Diag_Interval = '^([2-9]|[1-9][0-9]|[1-6][0-9]{2}|7[01][0-9]|720)$'
  static unixtime_interval = '^([5-9]|[1-9][0-9]{1,2}|[1-3][0-9]{3}|4[0-2][0-9]{2}|43[01][0-9]|4320)$'
  static Instantaneous_profile_interval = '^([1-9]|[12][0-9]|30)$'
  static dailyloadprofileintervalhour = '^([0-9]|1[0-2])$'
  static dailyloadprofileintervalminute = '^([0-9]|[1-5][0-9]|60)$'
  static networkName = '^[a-zA-Z0-9@ _-]*$'
  static panSize = '^([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$'
  static txPower = '^([0-9]|1[0-9]|20)$'
  static panId = '^([0-9]|[1-9][0-9]{1,8}|1[0-9]{9}|20[0-9]{8}|21[0-3][0-9]{7}|214[0-6][0-9]{6}|2147[0-3][0-9]{5}|21474[0-7][0-9]{4}|214748[0-2][0-9]{3}|2147483[0-5][0-9]{2}|21474836[0-3][0-9]|214748364[0-7])$'
  static asyncFragDuration = '^(50[0-9]|5[1-9][0-9]|[6-9][0-9]{2}|[1-9][0-9]{3,8}|[1-3][0-9]{9}|4[01][0-9]{8}|42[0-8][0-9]{7}|429[0-3][0-9]{6}|4294[0-8][0-9]{5}|42949[0-5][0-9]{4}|429496[0-6][0-9]{3}|4294967[01][0-9]{2}|42949672[0-8][0-9]|429496729[0-5])$'
  static gtkNewInstallReq = '^([0-9]|[1-9][0-9]|100)$';
  static gtkNewActivationTime = '^([1-9]|[1-9][0-9]{1,8}|1[0-9]{9}|20[0-9]{8}|21[0-3][0-9]{7}|214[0-6][0-9]{6}|2147[0-3][0-9]{5}|21474[0-7][0-9]{4}|214748[0-2][0-9]{3}|2147483[0-5][0-9]{2}|21474836[0-3][0-9]|214748364[0-7])$'
  static pansize = '^([0-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$'
  static singleEmailPattern = '^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
  static notifyDownloadChunkPattern = '^(1[0-9]|[2-9][0-9]|1[0-9]{2}|200)$'
  static nameValidationPattern = '^[a-zA-Z0-9_]+$';
  static hexaValidationPattern = '^[a-fA-F0-9]+$';
  static pageSize = 50;
  static firmwareTypePattern = '^([0-9]|[1-9][0-9]{1,8}|[1-3][0-9]{9}|4[01][0-9]{8}|42[0-8][0-9]{7}|429[0-3][0-9]{6}|4294[0-8][0-9]{5}|42949[0-5][0-9]{4}|429496[0-6][0-9]{3}|4294967[01][0-9]{2}|42949672[0-8][0-9]|429496729[0-5])$';
  static artifactSizePattern = '^([0-8])$'

  static isNullOrEmpty(data) {
    if (data) {
      return false;
    } else {
      return true;
    }
  }

  static getUTCDate() {
    return (new Date()).toUTCString();
  }

  static getAuthorizHeader() {
    Common.setToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + Common.token
    });
    return headers;
  }

  static getAuthorizHeaderForZipFile() {
    Common.setToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      //  'responseType': 'arraybuffer',
      Authorization: 'Bearer ' + Common.token
    });

    return headers;
  }

  static getUnauthorizHeader() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }


  static addTokenInHeader(token) {
    const headers = new HttpHeaders({
      Authorization: 'bearer ' + token
    });
    return headers;
  }

  static getLoginHeader(): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }

  static setToken() {
    if (!Common.isNullOrEmpty(localStorage.getItem(LocalStorageKeys.access_token))) {
      const userModel = JSON.parse(localStorage.getItem(LocalStorageKeys.access_token));
      Common.token = userModel.access_token;
      Common.userId = userModel.userId;
      Common.organizationName = 'Admin';
      Common.userRole = userModel.roles[0];
    } else {
      window.location.replace('/auth/login');
    }
  }

  static parseInt(x, base) {
    const parsed = parseInt(x, base);
    if (isNaN(parsed)) { return 0; }
    return parsed;
  }

  static timeAgoDateConvert(comparisonDate) {
    let seconds = parseInt(comparisonDate, 10);
    let days = Math.floor(seconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    let hrs = Math.floor(seconds / 3600);
    seconds -= hrs * 3600;
    let mnts = Math.floor(seconds / 60);
    seconds -= mnts * 60;
    let daysString = days + ' days';
    let hrsString = hrs + ' Hrs';
    let mntsString = mnts + ' Mins';
    let secondsString = seconds + ' Secs';
    if (days == 0) {
      daysString = '';
    }
    if (hrs == 0) {
      hrsString = '';
    }
    if (mnts == 0) {
      mntsString = '';
    }
    if (seconds == 0) {
      secondsString = '';
    }
    return ' From ' + daysString + ' ' + hrsString + ' ' + mntsString + ' ' + secondsString;
  }

  static getFotaAuthorizHeader() {
    Common.setFotaToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + Common.FotaToken,
      'version': 'v2'
    });
    return headers;
  }
  static getFotaAuthorizHeaderV1() {
    Common.setFotaToken();
    const headers = new HttpHeaders({

      Authorization: 'Bearer ' + Common.FotaToken,
      'version': 'v1'
    });
    return headers;
  }
  static getFotaAuthorizHeaderV1WithJSON() {
    Common.setFotaToken();
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + Common.FotaToken,
      'version': 'v1'
    });
    return headers;
  }

  static getAuthorizHeaderForFile() {
    Common.setFotaToken();
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + Common.FotaToken,
      'version': 'v1'
    });

    return headers;
  }

  static getAuthorizHeaderForFileForFOTA() {
    Common.setToken();
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + Common.token,
    });
    return headers;
  }



  static setFotaToken() {
    if (!Common.isNullOrEmpty(localStorage.getItem(LocalStorageKeys.fota_access_token))) {
      const userModel = JSON.parse(localStorage.getItem(LocalStorageKeys.fota_access_token));
      Common.FotaToken = userModel.access_token;
    } else {
      window.location.replace('/auth/login');
    }
  }



  public static nodeSystemInfoProperties: NameValue<string>[] = [{ name: 'HW Version', value: 'hwVersion' }, { name: 'Product Id', value: 'productId' }, { name: 'Vendor Id', value: 'vendorId' },
  { name: 'SDK Version', value: 'sdkVersion' }, { name: 'RPL Neighbour Count', value: 'neighbourcount' }, { name: 'RPL Rank', value: 'rplRank' }, { name: 'Device Parent', value: 'deviceParent' },
  { name: 'Channel', value: 'channel' }, { name: 'PAN Id', value: 'panId' }, { name: 'TX Power', value: 'txPower' }, { name: 'RPL Etx Threshold Multiplier', value: 'rplEtxThresholdMultiplier' }, { name: 'RPL Lqi Threshold', value: 'rplLqiThreshold', }
    , { name: 'Ipv6', value: 'ipv6' }, { name: 'Port', value: 'port' }, { name: 'Parent RSSI', value: 'rssi' }, { name: 'Parent RSSI Out', value: 'rsiout' }, { name: 'Wisun Device Type', value: 'wisundevicetype' }, { name: 'Wisun Phy Config Type', value: 'wisunphyconfigtype' }];


  public static borderrouterSystemInfoProperties: NameValue<string>[] = [{ name: 'Product Id', value: 'productId' }, { name: 'Vendor Id', value: 'vendorId' }, { name: 'S.N', value: 'serialNumber' }, { name: 'Board Version', value: 'boardVersion' }, { name: 'HW Version', value: 'hwVersion' },
  { name: 'SDK Version', value: 'sdkVersion' }, { name: 'CPU Info', value: 'cpuInfo' }
  ];

  public static tun0InfoProperties: NameValue<string>[] = [
    { name: 'Interface Mac', value: 'interfaceMac' }, { name: 'IPV4', value: 'ipv4' }, { name: 'IPV6', value: 'ipv6' }, { name: 'HW Version', value: 'hwVersion' },
    { name: 'SDK Version', value: 'sdkVersion' }, { name: 'Channel', value: 'channel' }, { name: 'PAN Id', value: 'panId' }, { name: 'TX Power', value: 'txPower' },
    { name: 'Network Name', value: 'networkName' }, { name: 'Size', value: 'size' },
    { name: 'Domain', value: 'domain' }, { name: 'FanVersion', value: 'fanVersion' }, { name: 'Class', value: 'class' }, { name: 'Mode', value: 'mode' },
    { name: 'Chan Pan Id', value: 'chanPanId' }, { name: 'Phy Mode Id', value: 'phyModId' }];

  public static tun1InfoProperties: NameValue<string>[] = [
    { name: 'Interface Mac', value: 'interfaceMac' }, { name: 'IPV4', value: 'ipv4' }, { name: 'IPV6', value: 'ipv6' }, { name: 'HW Version', value: 'hwVersion' },
    { name: 'SDK Version', value: 'sdkVersion' }, { name: 'Channel', value: 'channel' }, { name: 'PAN Id', value: 'panId' }, { name: 'TX Power', value: 'txPower' },
    { name: 'Network Name', value: 'networkName' }, { name: 'Size', value: 'size' },
    { name: 'Domain', value: 'domain' }, { name: 'FanVersion', value: 'fanVersion' }, { name: 'Class', value: 'class' }, { name: 'Mode', value: 'mode' },
    { name: 'Chan Pan Id', value: 'chanPanId' }, { name: 'Phy Mode Id', value: 'phyModId' }];


  public static ppp0Infoproperties: NameValue<string>[] = [{ name: 'Interface Mac', value: 'interfaceMac' }, { name: 'IPV4', value: 'ipv4' }, { name: 'IPV6', value: 'ipv6' }, { name: 'Net Mask', value: 'netMask' }, { name: 'GW Mask', value: 'gwMask' }, { name: 'IMEI Number', value: 'imeiNumber' }, { name: 'Interface Type', value: 'interfaceType' }, { name: 'Interface Active', value: 'interfaceActive' }];
  public static eth0InfoProperties: NameValue<string>[] = [{ name: 'Interface Mac', value: 'interfaceMac' }, { name: 'IPV4', value: 'ipv4' }, { name: 'IPV6', value: 'ipv6' }, { name: 'Net Mask', value: 'netMask' }, { name: 'GW Mask', value: 'gwMask' }, { name: 'Interface Type', value: 'interfaceType' }, { name: 'Interface Active', value: 'interfaceActive' }];
  public static wlan0InfoProperties: NameValue<string>[] = [{ name: 'Interface Mac', value: 'interfaceMac' }, { name: 'IPV4', value: 'ipv4' }, { name: 'IPV6', value: 'ipv6' }, { name: 'Net Mask', value: 'netMask' }, { name: 'GW Mask', value: 'gwMask' }, { name: 'Interface Type', value: 'interfaceType' }, { name: 'Interface Active', value: 'interfaceActive' }];
  public static othersProperties: NameValue<string>[] = [{ name: 'Interface Mac', value: 'interfaceMac' }, { name: 'IPV4', value: 'ipv4' }, { name: 'IPV6', value: 'ipv6' }, { name: 'Net Mask', value: 'netMask' }, { name: 'GW Mask', value: 'gwMask' }, { name: 'Interface Type', value: 'interfaceType' }, { name: 'Interface Active', value: 'interfaceActive' }];

  public static gatewayBatteryConfig: NameValue<string>[] = [{ name: 'Configuration File Version', value: 'fileversion' }, { name: 'DCU Battery Status', value: 'bstatus' }, { name: 'DCU Battery Cut-off-Voltage', value: 'bcutoffvol' }, { name: 'DCU Battery Max-Voltage', value: 'bmaxvol' }]
  public static gatewayServerConfig: NameValue<string>[] = [{ name: 'Configuration File Version', value: 'fileversion' }, { name: 'Coap Server Ipv4 Address', value: 'coapipv4' }, { name: 'Coap Server Ipv6 Address', value: 'coapipv6' }, { name: 'Coap Server Network Name', value: 'networktype' }, { name: 'Radius Server Ipv4 Address', value: 'radiusipv4' }, { name: 'Radius Server Ipv6 Address', value: 'radiusipv6' }, { name: 'Radius Server Password', value: 'radiuspassword' },
  { name: 'Ping Server Ipv4 Address', value: 'pingserveripv4' }, { name: 'Ping Server Ipv6 Address', value: 'pingserveripv6' }, { name: 'Ping Interval', value: 'pinginterval' }, { name: 'Network Diag Interval', value: 'networkdiagonsticinterval' }, { name: 'Power Interval', value: 'powerinterval' }, { name: 'Heart-Beat Interval', value: 'heartbeatinterval' }, { name: 'Coap Server Ipv4 Address Port', value: 'coapipv4port' },
  { name: 'Coap Server Ipv6 Address Port', value: 'coapipv6port' }, { name: 'Radius Server Port1', value: 'radiusport1' }, { name: 'Radius Server Port2', value: 'radiusport2' }, { name: 'HES Coap Server Ipv4 Address', value: 'hescoapipv4' }, { name: 'HES Server Ipv6 Address', value: 'hescoapipv6' }, { name: 'HES Server 65 Ipv4 Address Port', value: 'hescoapipv4port' }, { name: "HES Server 67 Ipv4 Address Port", value: "hdataipv4port" }, { name: 'HES Server 65 Ipv6 Address Port', value: 'hescoapipv6port' },
  { name: "HES Server 67 Ipv6 Address Port", value: "hdataipv6port" }, { name: 'Rtty Server Ip-Address', value: 'rttyip' }, { name: 'Rtty Server Port', value: 'rttyport' },
  { name: "Sftp Server Ipv4 Address", value: "sftpipv4" }, { name: "Sftp Server Ipv6 Address", value: "sftpipv6" }, { name: "SftpPortV4", value: "sftpipv4port" }, { name: "SftpPortV6", value: "sftpipv6port" }, { name: "Sftp Username", value: "sftpusername" }, { name: "Sftp Password", value: "sftppassword" }, { name: "Sftp Path", value: "sftppath" }]
  public static gatewayLogConfig: NameValue<string>[] = [{ name: 'Configuration File Version', value: 'fileversion' }, { name: 'Log Status', value: 'logstatus' }]
  public static gatewayWisunConfig: NameValue<string>[] = [{ name: 'Configuration File Version', value: 'fileversion' }, { name: 'Network Name', value: 'networkname' }, { name: 'Domain', value: 'domain' }, { name: 'Class', value: 'wClass' }, { name: 'Mode', value: 'mode' }, { name: 'Size', value: 'size' }, { name: 'Tx-Power', value: 'txPower' }
    , { name: 'Radius Server Authantication', value: 'authstatus' }, { name: 'Internal-DHCP', value: 'internalDHCP' }, { name: 'Pan Id', value: 'panid' }, { name: 'Channel Frequency', value: 'chanFreqEnable' }, { name: 'Chan0_Freq', value: 'chan0Freq' }, { name: 'Chan-Spacing', value: 'chanspacing' }, { name: 'Chan-Count', value: 'chancount' }, { name: 'Unicast-Dwell-Interval', value: 'unicastdwellinterval' }, { name: 'Broadcast-Dwell-Interval', value: 'brodcastdwellinterval' }
    , { name: 'Broadcast-Interval', value: 'brodcastinterval' }, { name: 'Lfn-Broadcast-Interval', value: 'lfnbrodcastinterval' }, { name: 'Lfn-Broadcast-Sync-Period', value: 'lfnbrodcastsyncinterval' }, { name: 'Async-Frag-Duration', value: 'asyncFragDuration' }, { name: 'Lowpan-Mtu', value: 'lowpanmtu' }, { name: 'No.of GTK Dropdown', value: 'gtk' }, { name: 'Pmk-Lifetime', value: 'pmkLifeTime' }, { name: 'Ptk-Lifetime', value: 'ptkLifeTime' }, { name: 'Gtk-Lgtk Expire Offset', value: 'gtklgtkenable' }, { name: 'Gtk-Expire-Offset', value: 'gtkExpireOffset' },
  { name: 'Lgtk-Expire-Offset', value: 'lgtkExpireOffset' }, { name: 'Gtk-New-Activation-Time', value: 'gtknewactivationtime' }, { name: 'Lgtk-New-Activation-Time ', value: 'lgtkNewActivationTime' }, { name: ' Gtk-New-Install-Required', value: 'gtknewinstallreq' }, { name: 'Lgtk-New-Install-Required', value: 'lgtkNewInstallReq' }, { name: 'Ffn-Revocation-Lifetime-Reduction', value: 'ffnRevocationLifeTimeReduction' }, { name: ' Lfn-Revocation-Lifetime-Reduction', value: 'lfnRevocationLifeTimeReduction' }
    , { name: 'Pan-Size', value: 'panSize' }, { name: 'Make Deside Sending Mac', value: 'sendMacEnable' }, { name: 'Number of Allow or Denied Mac', value: 'numOfAllowMac' }, { name: 'Mac64', value: 'mac64' }, { name: 'Pcap File Path Status', value: 'pcapfilepathEnable' }, { name: 'Pcap File Path', value: 'pcapfilepath' }, { name: 'Trace', value: 'trace' }, { name: 'Network Type', value: 'networkType' }, { name: 'Wi-sun BRpl_Rpi_Ignorable', value: 'isBRplRpiIgnorable' }, { name: 'InterFace Type', value: 'interfaceType' },
  { name: 'Gtk Lgtk New Activation Time', value: 'ltkLgtkNewTimeEanable' }, { name: 'GTK0', value: 'gtk0' }, { name: 'GTK1', value: 'gtk1' }, { name: 'GTK2', value: 'gtk2' }, { name: ' GTK3', value: 'gtk3' }, { name: 'Wi-Sun Uart Device', value: 'uartDevice' }, { name: 'Wi-Sun Fan Version Status', value: 'fanVersionStatus' }]

  public static endNodeConfig: NameValue<string>[] = [{ name: 'Network_Diagnostic_Interval', value: 'networkdiagonsticinterval' }, { name: 'UnixTime_Interval', value: 'unixtimeinterval' }]
  public static endNodeWisunConfig: NameValue<string>[] = [{ name: 'Network Name', value: 'network_name' }, { name: 'Network Size', value: 'network_size' }]
  public static endNodeServerConfig: NameValue<string>[] = [{ name: 'Server', value: 'coapipv6' }, { name: 'Port', value: 'coapport' }, { name: 'HES Server', value: 'hescoapipv6' }, { name: 'HES Port', value: 'hescoapport' }, { name: 'HES Data Port', value: 'hesDataPort' }, { name: 'Condition', value: 'condition' }]
  public static endNodeMeterConfig: NameValue<string>[] = [{ name: 'Instantaneous Profile Time Interval', value: 'instantaneousprofileinterval' }, { name: 'Instantaneous Profile Status', value: 'instantaneousprofileflag' },
  { name: 'Block Load Profile Time Interval', value: 'blockloadprofileinterval' }, { name: 'Block Load Profile Status', value: 'blockloadprofileflag' }, { name: 'Daily Load Profile Status', value: 'dailyloadprofileflag' }, { name: 'DailyLoad_Profile_Time_Hour', value: 'dailyloadprofiletimehour' }, { name: 'DailyLoad_Profile_Time_Min', value: 'dailyloadprofiletimemin' }, { name: 'Billing _Profile_Time_Hour', value: 'billingprofiletimehour' }, { name: 'Billing _Profile_Time_Min', value: 'billingprofiletimemin' },
  { name: 'Billing Profile Time Status', value: 'billingprofileflag' }
  ]
  public static endNodeLogConfig: NameValue<string>[] = [{ name: "Log Level", value: "loglevel" }, { name: "Log Flag", value: "logflag" }]
  public static endNodeOtaConfig: NameValue<string>[] = [{ name: "VerboseMode", value: "verboseMode" },
  { name: 'Auto Install', value: 'autoInstall' }, { name: 'Host Notify', value: 'hostNotify' }, { name: 'Notify Host Address', value: 'notifyHostAddr' },
  { name: 'Notify Download Chunk Cnt', value: 'notifyDownloadChunkCnt' }, { name: 'Notify Port', value: 'notifyPort' }]

  public static pageSizeItems: PageSizeItem[] = [
    { text: '5', value: 5 }, { text: '10', value: 10 }, { text: '20', value: 20 }, {text: '50', value: 50
    }, { text: '100', value: 100 }, { text: '1000', value: 1000 }, {text: '5000', value: 5000 }, { text: 'All', value: 'all' }];

   
}



